import cacheUtils from 'store'

/** 广告今日不再提醒缓存 */
export function setAdvertTipsTime(key: string, time: string | number) {
  return cacheUtils.set(key, time)
}

/** 获取当前广告缓存日期 */
export function getAdvertTipsTime(key: string) {
  return cacheUtils.get(key) || '0'
}
