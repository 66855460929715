import { getIsLogin } from '@/helper/auth'
import { link } from '../link'
import { mateAdvertEnum } from '../common'

/** 广告跳转链接 */
export function advertTolink(imageInfoList, index) {
  if (!imageInfoList[index]?.linkUrl) {
    return
  }
  if (imageInfoList[index]?.linkUrl?.includes('http')) {
    return getIsLogin() ? window.open(imageInfoList[index]?.linkUrl) : link('/login')
  }
  return link(mateAdvertEnum(imageInfoList[index]?.linkUrl))
}
